import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './usuarioForm';

import { setModoTela, initForm, salvar, excluir, getLista, getListaPerfil, getListaCliente, getListaCargo, getListaUnidade, getListaSetor } from './usuarioActions';
import DateFormat from '../common/dateFormat/DateFormat';
import ClienteUtils from '../common/utils/ClienteUtils';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';
import Select from '../common/form/select';

class UsuarioColaborador extends Component {

	state = {
		linhaSelecionada: null,
		pesquisar: '',
		filtro: {

		}
	}

    componentWillMount() {
        this.props.getLista();
		this.props.getListaPerfil();
		this.props.getListaCliente();
		this.props.getListaUnidade();
		this.props.getListaSetor();
		this.props.getListaCargo();
    }

    render() {
        return (
            <div>
                <Content>
					{this.props.modoTela == 'cadastro' ? (
						<Form colaborador onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'cadastroConf' ? (
						<Form colaborador configuracao onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form colaborador excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
                </Content>
            </div>
        )
    }

	lista() {

		let lista = (this.props.lista).filter(item => {
			if ((this.state.inativos && !item.data_desligamento) || (!this.state.inativos && item.data_desligamento)) {
				return false;
			}
			if (this.state.pesquisar
				&& !(`
					${item.nome} ${item.email} ${item.nome_perfil || ''} ${item.login || ''}
					${item.nome_cliente || ''} ${item.nome_unidade || ''} ${item.nome_setor || ''}
					${item.nome_cargo || ''} ${item.nome_sexo || ''}
				`).toUpperCase().includes(this.state.pesquisar.toUpperCase())) {
				return false;
			}
			if (this.state.filtro.id_cliente && item.id_cliente != this.state.filtro.id_cliente) {
				return false;
			}
			if (this.state.filtro.id_unidade && item.id_unidade != this.state.filtro.id_unidade) {
				return false;
			}
			if (this.state.filtro.id_setor && item.id_setor != this.state.filtro.id_setor) {
				return false;
			}
			return true;
		});

		return (
			<ContentCard>
				<ContentCardHeader>
					<Row>
						<Grid cols='6 6 4 2'>
							<Button
								text='Adicionar'
								type={'success'}
								icon={'fa fa-plus'}
								event={() => {
									this.props.setModoTela('cadastro', { id_perfil: 3 });
									this.props.initForm({ id_perfil: 3 });
								}} />
						</Grid>
					</Row>
				</ContentCardHeader>
				<ContentCardBody>
					<Row>
						<Select
							options={this.props.listaCliente}
							label='Cliente'
							cols='12 2 2 2'
							placeholder='Selecione'
							value={this.state.filtro.id_cliente}
							onChange={value => {
								this.setState({
									...this.state,
									filtro: {
										...this.state.filtro,
										id_cliente: value
									}
								});
							}} />

						<Select 
							options={this.props.listaUnidade.filter(item => this.state.filtro.id_cliente && item.id_cliente == this.state.filtro.id_cliente)}
							label='Unidade'
							cols='12 2 2 2'
							placeholder='Selecione'
							value={this.state.filtro.id_unidade}
							onChange={value => {
								this.setState({
									...this.state,
									filtro: {
										...this.state.filtro,
										id_unidade: value
									}
								});
							}} />

						<Select 
							options={this.props.listaSetor.filter(item => this.state.filtro.id_unidade && item.id_unidade == this.state.filtro.id_unidade)}
							label='Setor'
							cols='12 2 2 2'
							placeholder='Selecione'
							value={this.state.filtro.id_setor}
							onChange={value => {
								this.setState({
									...this.state,
									filtro: {
										...this.state.filtro,
										id_setor: value
									}
								});
							}} />

						<LabelAndCheckbox 
							label='Inativos'
							cols='12 2 2 2'
							placeholder='Selecione'
							value={this.state.inativos}
							onChange={() => {
								this.setState({
									...this.state,
									inativos: !this.state.inativos
								})
							}} />
					</Row>
					<Row>
						<Grid cols='12 8 8 8'>
							<input className='form-control'
								placeholder='Pesquisar'
								type='text'
								value={this.state.pesquisar}
								onChange={e => {
									this.setState({ ...this.state, pesquisar: e.target.value });
								}} />
						</Grid>
						<Grid cols='12 4 4 4'>
							<h6 style={{ padding: 0, margin: 0, textAlign: 'right' }}>{lista.length} {lista.length > 1 ? 'colaboradores' : 'colaborador'}</h6>
						</Grid>
					</Row>

					<Table responsive>
						<THead>
							<Tr>
								<Th>Nome</Th>
								<Th alignCenter>E-mail</Th>
								<Th alignCenter>Perfil</Th>
								<Th alignCenter>Cliente</Th>
								<Th alignCenter>Unidade</Th>
								<Th alignCenter>Setor</Th>
								<Th alignCenter>Cargo</Th>
								<Th alignCenter>Sexo</Th>
								<Th></Th>
							</Tr>
						</THead>
						<TBody>
							{lista.map(item => (
								<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
									onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
									onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
									<Td>{item.nome}</Td>
									<Td alignCenter>{item.email}</Td>
									<Td alignCenter>{item.nome_perfil}</Td>
									<Td alignCenter>{item.nome_cliente}</Td>
									<Td alignCenter>{item.nome_unidade}</Td>
									<Td alignCenter>{item.nome_setor}</Td>
									<Td alignCenter>{item.nome_cargo}</Td>
									<Td alignCenter>{item.nome_sexo}</Td>
									<Td alignRight minWidth={100}>
										<ButtonTable
											type={'primary'}
											icon={'fas fa-pencil-alt'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.setModoTela('cadastroConf', {
													...item,
													data_nascimento: DateFormat.formatarDataSqlParaTela(item.data_nascimento),
													data_admissao: DateFormat.formatarDataSqlParaTela(item.data_admissao),
													data_desligamento: DateFormat.formatarDataSqlParaTela(item.data_desligamento)
												});
												this.props.initForm({
													...item,
													data_nascimento: DateFormat.formatarDataSqlParaTela(item.data_nascimento),
													data_admissao: DateFormat.formatarDataSqlParaTela(item.data_admissao),
													data_desligamento: DateFormat.formatarDataSqlParaTela(item.data_desligamento)
												});
											}} />

										<ButtonTable
											type={'warning'}
											icon={'fas fa-lock'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.setModoTela('cadastro', {
													...item,
													data_nascimento: DateFormat.formatarDataSqlParaTela(item.data_nascimento),
													data_admissao: DateFormat.formatarDataSqlParaTela(item.data_admissao),
													data_desligamento: DateFormat.formatarDataSqlParaTela(item.data_desligamento)
												});
												this.props.initForm({
													...item,
													data_nascimento: DateFormat.formatarDataSqlParaTela(item.data_nascimento),
													data_admissao: DateFormat.formatarDataSqlParaTela(item.data_admissao),
													data_desligamento: DateFormat.formatarDataSqlParaTela(item.data_desligamento)
												});
											}} />

										<ButtonTable
											type={'danger'}
											icon={'fas fa-trash-alt'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.setModoTela('exclusao', {
													...item,
													data_nascimento: DateFormat.formatarDataSqlParaTela(item.data_nascimento),
													data_admissao: DateFormat.formatarDataSqlParaTela(item.data_admissao),
													data_desligamento: DateFormat.formatarDataSqlParaTela(item.data_desligamento)
												});
												this.props.initForm({
													...item,
													data_nascimento: DateFormat.formatarDataSqlParaTela(item.data_nascimento),
													data_admissao: DateFormat.formatarDataSqlParaTela(item.data_admissao),
													data_desligamento: DateFormat.formatarDataSqlParaTela(item.data_desligamento)
												});
											}} />
									</Td>
								</Tr>
							))}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.usuario.modoTela,
	lista: ClienteUtils.filtrarLista(state.usuario.lista, state.auth.usuario),
	listaCliente: ClienteUtils.filtrarLista(state.usuario.listaCliente.map(item => ({ ...item, id_cliente: item.id })), state.auth.usuario),
	listaUnidade: state.usuario.listaUnidade,
	listaSetor: state.usuario.listaSetor
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm, salvar, excluir, getLista, getListaPerfil, getListaCliente, getListaCargo, getListaUnidade, getListaSetor }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(UsuarioColaborador);
