import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './chamadaForm';
import { setError } from '../common/toast/toast';
import readXlsxFile from 'read-excel-file/web-worker'
import Aguardando from '../common/template/aguardando';

import { setModoTela, initForm, salvar, excluir, getLista, getListaCliente, getListaUnidade, getListaSetor, getListaMensal, getListaMensalParticipacao, imprimirMensal } from './chamadaActions';
import DateFormat from '../common/dateFormat/DateFormat';
import ClienteUtils from '../common/utils/ClienteUtils';
import Select from '../common/form/select';
import FormatUtils from '../common/formatUtils/FormatUtils';

class ChamadaMensal extends Component {

	state = {
		linhaSelecionada: null,
		paginacaoIndice: 0,
		filtro: {
			id_cliente: null,
			id_unidade: null,
			id_setor: null,
			ano: new Date().getFullYear(),
			mes: String(new Date().getMonth() + 1).padStart(2, '0')
		}
	}

	componentDidMount() {
		this.props.getListaMensal({
			id_cliente: this.state.filtro.id_cliente || 0,
			id_unidade: this.state.filtro.id_unidade || 0,
			id_setor: this.state.filtro.id_setor || 0,
			ano: this.state.filtro.ano || 0,
			mes: this.state.filtro.mes || 0
		});
		this.props.getListaMensalParticipacao({
			id_cliente: this.state.filtro.id_cliente || 0,
			id_unidade: this.state.filtro.id_unidade || 0,
			id_setor: this.state.filtro.id_setor || 0,
			ano: this.state.filtro.ano || 0,
			mes: this.state.filtro.mes || 0
		});
	}

    componentWillMount() {
        this.props.getLista();
		this.props.getListaCliente();
		this.props.getListaUnidade();
		this.props.getListaSetor();
    }

    render() {
        return (
            <div>
                <Content>
					<Aguardando aguardando={this.props.aguardando} />

					{this.lista()}

                </Content>
            </div>
        )
    }

	atualizarFiltro(filtro) {

		this.setState({
			...this.state,
			filtro: {
				...filtro
			}
		});

		this.props.getListaMensal({
			id_cliente: filtro.id_cliente || 0,
			id_unidade: filtro.id_unidade || 0,
			id_setor: filtro.id_setor || 0,
			ano: filtro.ano || 0,
			mes: filtro.mes || 0
		});
		this.props.getListaMensalParticipacao({
			id_cliente: filtro.id_cliente || 0,
			id_unidade: filtro.id_unidade || 0,
			id_setor: filtro.id_setor || 0,
			ano: filtro.ano || 0,
			mes: filtro.mes || 0
		});
	}	

	lista() {

		let lista = (this.props.lista).filter(item => {
			if (this.state.pesquisar
				&& !(`${item.nome}`).toUpperCase().includes(this.state.pesquisar.toUpperCase())) {
				return false;
			}
			return true;
		});

		let presencas = 0;
		let ausencias = 0;
		let faltas = 0;
		let reunioes = 0;
		let feriados = 0;

		lista.forEach(item => { 
			presencas += item.dias.filter(dia => dia.sigla == 'P').length;
			ausencias += item.dias.filter(dia => dia.sigla == 'A').length;
			faltas += item.dias.filter(dia => dia.sigla == 'F').length;
			reunioes += item.dias.filter(dia => dia.sigla == 'R').length;
			feriados += item.dias.filter(dia => dia.sigla == 'Fe').length;
		});

		let listaMes = [
			{ id: '01', valor: 'Janeiro' }, { id: '02', valor: 'Fevereiro' }, { id: '03', valor: 'Março' }, { id: '04', valor: 'Abril' },
			{ id: '05', valor: 'Maio' }, { id: '06', valor: 'Junho' }, { id: '07', valor: 'Julho' }, { id: '08', valor: 'Agosto' }, 
			{ id: '09', valor: 'Setembro' }, { id: '10', valor: 'Outubro' }, { id: '11', valor: 'Novembro' }, { id: '12', valor: 'Dezembro' }
		];

		return (
			<ContentCard>
				<ContentCardHeader>
					<Row>
						<Select
							options={this.props.listaCliente}
							label='Cliente'
							cols='12 2 2 2'
							placeholder='Selecione'
							value={this.state.filtro.id_cliente}
							onChange={value => {
								this.atualizarFiltro({
									...this.state.filtro,
									id_cliente: value
								});
							}} />

						<Select 
							options={this.props.listaUnidade.filter(item => this.state.filtro.id_cliente && item.id_cliente == this.state.filtro.id_cliente)}
							label='Unidade'
							cols='12 2 2 2'
							placeholder='Selecione'
							value={this.state.filtro.id_unidade}
							onChange={value => {
								this.atualizarFiltro({
									...this.state.filtro,
									id_unidade: value
								});
							}} />

						<Select 
							options={this.props.listaSetor.filter(item => this.state.filtro.id_unidade && item.id_unidade == this.state.filtro.id_unidade)}
							label='Setor'
							cols='12 2 2 2'
							placeholder='Selecione'
							value={this.state.filtro.id_setor}
							onChange={value => {
								this.atualizarFiltro({
									...this.state.filtro,
									id_setor: value
								});
							}} />

						<Select 
							options={Array.from(Array(20).keys()).map(item => ({ id: item + 2015, valor: String(item + 2015).padStart(2, '0') }))}
							label='Ano'
							cols='12 2 2 2'
							placeholder='Selecione'
							value={this.state.filtro.ano}
							onChange={value => {
								this.atualizarFiltro({
									...this.state.filtro,
									ano: value
								});
							}} />
						
						<Select 
							options={listaMes}
							label='Mês'
							cols='12 2 2 2'
							placeholder='Selecione'
							value={this.state.filtro.mes}
							onChange={value => {
								this.atualizarFiltro({
									...this.state.filtro,
									mes: value
								});
							}} />

						<Grid cols='6 6 3 2' style={{ marginTop: 28 }}>
							<Button
								text='Imprimir'
								type={'primary'}
								icon={'fa fa-chevron-left'}
								event={() => {
									this.props.imprimirMensal({
										id_cliente: this.state.filtro.id_cliente,
										id_unidade: this.state.filtro.id_unidade,
										id_setor: this.state.filtro.id_setor,
										nome: this.props.listaCliente.filter(item => item.id == this.state.filtro.id_cliente).map(item => item.valor).join('')
											+ ' - ' + this.props.listaUnidade.filter(item => item.id == this.state.filtro.id_unidade).map(item => item.valor).join('')
											+ ' - ' + this.props.listaSetor.filter(item => item.id == this.state.filtro.id_setor).map(item => item.valor).join(''),
										ano: this.state.filtro.ano || '',
										id_mes: this.state.filtro.mes,
										mes: listaMes.filter(item => item.id == this.state.filtro.mes).map(item => item.valor).join(''),
										lista: lista,
										listaParticipacao: this.props.listaMensalParticipacao
									});
								}} />
						</Grid>
					</Row>
				</ContentCardHeader>
				<ContentCardBody>
					<input className='form-control'
						 placeholder='Pesquisar'
						 type='text'
						 value={this.state.pesquisar}
						 onChange={e => {
							 this.setState({ ...this.state, pesquisar: e.target.value, paginacaoIndice: 0 });
						}} />

					<Table responsive border>
						<THead>
							<Tr>
								<Th>Nome</Th>
								{(lista && lista[0] && lista[0].dias ? lista[0].dias : []).map(dia => (
									<Th alignCenter>{dia.dia}</Th>
								))}
							</Tr>
						</THead>
						<TBody>
							{lista.map((item, i) => {

								//Utiliza pra definir se a coluna pé reunião ou feriado
								let itemInicial = lista[0];

								return (
									<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
										onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
										onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
										<Td>{item.nome}</Td>
										{item.dias.filter(dia => (itemInicial.dias.filter(diaAux => diaAux.dia == dia.dia)[0].sigla != 'Fe' 
											&& itemInicial.dias.filter(diaAux => diaAux.dia == dia.dia)[0].sigla != 'R') || i == 0).map(dia => (
											<Td alignCenter 
												rowspan={((dia.sigla == 'Fe' || dia.sigla == 'R') && i == 0) ? lista.length : 1}
												backgroundColor={dia.sigla == 'P' ? '#2bd951' : (
													dia.sigla == 'A' ? '#c3c904' : (
														dia.sigla == 'F' ? '#de7a66' : '#b8b8b8'
													)
												)}>
												{dia.sigla}
											</Td>
										))}
									</Tr>
								);
							})}
						</TBody>
					</Table>

					<Table responsive border>
						<THead>
							<Tr>
								<Th alignCenter>Colaboradores: {lista.length}</Th>
								<Th alignCenter>Dias: {(lista && lista[0] && lista[0].dias ? lista[0].dias : []).length}</Th>
								<Th alignCenter>Presenças (P): {presencas}</Th>
								<Th alignCenter>Ausências (A): {ausencias}</Th>
								<Th alignCenter>Faltas (F): {faltas}</Th>
							</Tr>
						</THead>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}
}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.chamada.modoTela,
	aguardando: state.chamada.aguardando,
	lista: state.chamada.listaMensal,
	listaMensalParticipacao: state.chamada.listaMensalParticipacao,
	listaCliente: ClienteUtils.filtrarLista(state.chamada.listaCliente.map(item => ({ ...item, id_cliente: item.id })), state.auth.usuario),
	listaUnidade: state.chamada.listaUnidade,
	listaSetor: state.chamada.listaSetor
});
const mapDispatchToProps = dispatch => bindActionCreators({ 
	setModoTela, initForm, salvar, excluir, getLista, getListaCliente, getListaUnidade, getListaSetor, getListaMensal, getListaMensalParticipacao, imprimirMensal
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ChamadaMensal);