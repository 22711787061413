const INITIAL_STATE = {
	registro: {},
	modoTela: 'lista',
	aguardando: false,
	lista: [],
	listaMensal: [],
	listaItem: [],
	listaCliente: [],
	listaUnidade: [],
	listaSetor: [],
	listaMensalParticipacao: [],
	listaPresenca: [{ id: 1, valor: 'Presente' }, { id: 2, valor: 'Falta' }, { id: 3, valor: 'Ausente' }]
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'CHAMADA_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

		case 'CHAMADA_SET_AGUARDANDO':
			return { ...state,
				aguardando: action.payload
			};

        case 'CHAMADA_LISTADO':
            return {
				...state,
				lista: action.payload.data
			};

		case 'CHAMADA_MENSAL_LISTADO':
            return {
				...state,
				listaMensal: action.payload.data
			};

		case 'CHAMADA_MENSAL_PARTICIPACAO_LISTADO':
            return {
				...state,
				listaMensalParticipacao: action.payload.data
			};

		case 'CHAMADA_ITEM_LISTADO':
            return {
				...state,
				listaItem: action.payload.data
			};

		case 'CHAMADA_CLIENTE_SELECT_LISTADO':
            return {
				...state,
				listaCliente: action.payload.data
			};

		case 'CHAMADA_UNIDADE_SELECT_LISTADO':
            return {
				...state,
				listaUnidade: action.payload.data
			};

		case 'CHAMADA_SETOR_SELECT_LISTADO':
            return {
				...state,
				listaSetor: action.payload.data
			};

        default:
            return state;
    }
}
