import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import { setError } from '../common/toast/toast';
import readXlsxFile from 'read-excel-file/web-worker'
import Aguardando from '../common/template/aguardando';
import LineChart from '../common/grafico/LineChart';

import { getListaAvaliacao } from './resultadoIndividualActions';
import { 
	getLista as getListaCliente
} from '../cliente/clienteActions';
import { 
	getLista as getListaUsuario
} from '../usuario/usuarioActions';
import Select from '../common/form/select';
import ClienteUtils from '../common/utils/ClienteUtils';
import FormatUtils from '../common/formatUtils/FormatUtils';

class ResultadoIndividual extends Component {

	state = {
		linhaSelecionada: null,
		paginacaoIndice: 0
	}

	componentDidMount() {

	}

    componentWillMount() {
		this.props.getListaCliente();
		this.props.getListaUsuario();

		let idInterval = setInterval(() => {
			if (this.state.id_usuario) {
				this.props.getListaAvaliacao(this.state.id_usuario);
			}
		}, 50000);
		this.setState({
			...this.state,
			idInterval: idInterval	
		});
    }

	componentWillUnmount() {
		clearInterval(this.state.idInterval);
    }

    render() {
        return (
            <div>
                <Content>
					<Aguardando aguardando={this.props.aguardando} />

					<ContentCard>
						<ContentCardHeader>
							<Row>

								<Select
									options={this.props.listaCliente}
									label='Cliente'
									cols='12 6 6 6'
									placeholder='Selecione o cliente'
									value={this.state.id_cliente}
									onChange={value => {
										this.setState({
											...this.state,
											id_cliente: value	
										});
										this.props.getListaAvaliacao(0);
									}} />

								<Select 
									options={this.props.listaUsuario.filter(item => this.state.id_cliente && item.id_cliente == this.state.id_cliente && !item.inativo)}
									label='Colaborador'
									cols='12 6 6 6'
									placeholder='Selecione o colaborador'
									value={this.state.id_usuario}
									onChange={value => {
										this.setState({
											...this.state,
											id_usuario: value	
										});
										this.props.getListaAvaliacao(value);
									}} />
							</Row>
						</ContentCardHeader>
					</ContentCard>

					{this.graficos()}

					{this.listas()}

                </Content>
            </div>
        )
    }

	graficos() {

		return (
			<div>
				<ContentCard>
					<ContentCardHeader>
						<h3 class='card-title'>Balança de Bioimpedância</h3>
					</ContentCardHeader>
					<ContentCardBody>

						<Table>
							<THead>
								<Tr>
									<Th>Resumo</Th>
									{this.props.listaAvaliacao.map(item => (
										<Th alignCenter>{item.ano}</Th>
									))}
								</Tr>
							</THead>
							<TBody>
								<Tr>
									<Td bold>Peso Corporal</Td>
									{this.props.listaAvaliacao.map(item => (
										<Td alignCenter>{FormatUtils.formatarValorTela(item.peso, 2)}</Td>
									))}
								</Tr>
								<Tr>
									<Td bold>Índice de Massa Corporal (IMC)</Td>
									{this.props.listaAvaliacao.map(item => (
										<Td alignCenter>{FormatUtils.formatarValorTela(item.imc, 2)}</Td>
									))}
								</Tr>
								<Tr>
									<Td bold>Gordura Corporal</Td>
									{this.props.listaAvaliacao.map(item => (
										<Td alignCenter>{FormatUtils.formatarValorTela(item.gordura_corporal, 2)}</Td>
									))}
								</Tr>
								<Tr>
									<Td bold>Gordura Visceral</Td>
									{this.props.listaAvaliacao.map(item => (
										<Td alignCenter>{FormatUtils.formatarValorTela(item.gordura_visceral, 2)}</Td>
									))}
								</Tr>
								<Tr>
									<Td bold>Músculos Esqueléticos</Td>
									{this.props.listaAvaliacao.map(item => (
										<Td alignCenter>{FormatUtils.formatarValorTela(item.musculo_esqueletico, 2)}</Td>
									))}
								</Tr>
								<Tr>
									<Td bold>Metabolismo Basal</Td>
									{this.props.listaAvaliacao.map(item => (
										<Td alignCenter>{FormatUtils.formatarValorTela(item.metabolismo_basal, 2)}</Td>
									))}
								</Tr>
								<Tr>
									<Td bold>Idade Corporal</Td>
									{this.props.listaAvaliacao.map(item => (
										<Td alignCenter>{FormatUtils.formatarValorTela(item.idade_corporal, 2)}</Td>
									))}
								</Tr>
							</TBody>
						</Table>

						<p style={{ backgroundColor: '#dbdbdb', textAlign: 'center', fontSize: 16, padding: 12 }}>
							<b>Mensuração corporal:</b> Utilizamos a Balança Bioimpedância Digital Corporal HBF 514C - Omrom, com Sensor de Corpo Inteiro que permite a medição completa através das mãos e pés.
						</p>


					</ContentCardBody>
				</ContentCard>
				<ContentCard>
					<ContentCardHeader>
						<h3 class='card-title'>Peso Corporal</h3>
					</ContentCardHeader>
					<ContentCardBody>

						<LineChart 
							id={`grafico-linha-peso-corporal`}
							height={300}
							data={this.props.listaAvaliacao.map(item => ({ id: item.id, categoria: String(item.ano), valor: parseFloat(item.peso) }))} />

					</ContentCardBody>
				</ContentCard>
				<ContentCard>
					<ContentCardHeader>
						<h3 class='card-title'>Índice de Massa Corporal (IMC)</h3>
					</ContentCardHeader>
					<ContentCardBody>

						<LineChart 
							id={`grafico-linha-imc`}
							height={300}
							data={this.props.listaAvaliacao.map(item => ({ id: item.id, categoria: String(item.ano), valor: parseFloat(item.imc) }))} />

					</ContentCardBody>
				</ContentCard>
				<ContentCard>
					<ContentCardHeader>
						<h3 class='card-title'>Gordura Corporal</h3>
					</ContentCardHeader>
					<ContentCardBody>

						<LineChart 
							id={`grafico-linha-gordura-corporal`}
							height={300}
							data={this.props.listaAvaliacao.map(item => ({ id: item.id, categoria: String(item.ano), valor: parseFloat(item.gordura_corporal) }))} />

					</ContentCardBody>
				</ContentCard>
				<ContentCard>
					<ContentCardHeader>
						<h3 class='card-title'>Gordura Visceral</h3>
					</ContentCardHeader>
					<ContentCardBody>

						<LineChart 
							id={`grafico-linha-gordura-visceral`}
							height={300}
							data={this.props.listaAvaliacao.map(item => ({ id: item.id, categoria: String(item.ano), valor: parseFloat(item.gordura_visceral) }))} />

					</ContentCardBody>
				</ContentCard>
				<ContentCard>
					<ContentCardHeader>
						<h3 class='card-title'>Músculos Esqueléticos</h3>
					</ContentCardHeader>
					<ContentCardBody>

						<LineChart 
							id={`grafico-linha-musculo-esqueletico`}
							height={300}
							data={this.props.listaAvaliacao.map(item => ({ id: item.id, categoria: String(item.ano), valor: parseFloat(item.musculo_esqueletico) }))} />

					</ContentCardBody>
				</ContentCard>
				<ContentCard>
					<ContentCardHeader>
						<h3 class='card-title'>Metabolismo Basal</h3>
					</ContentCardHeader>
					<ContentCardBody>

						<LineChart 
							id={`grafico-linha-metabolismo-basal`}
							height={300}
							data={this.props.listaAvaliacao.map(item => ({ id: item.id, categoria: String(item.ano), valor: parseFloat(item.metabolismo_basal) }))} />

					</ContentCardBody>
				</ContentCard>
				<ContentCard>
					<ContentCardHeader>
						<h3 class='card-title'>Idade Corporal</h3>
					</ContentCardHeader>
					<ContentCardBody>

						<LineChart 
							id={`grafico-linha-idade-corporal`}
							height={300}
							data={this.props.listaAvaliacao.map(item => ({ id: item.id, categoria: String(item.ano), valor: parseFloat(item.idade_corporal) }))} />

					</ContentCardBody>
				</ContentCard>
			</div>
		);
	}

	listas() {

		return (
			<div>
				<ContentCard>
					<ContentCardHeader>
						<h3 class='card-title'>Fumante</h3>
					</ContentCardHeader>
					<ContentCardBody>
						<Row>
							{this.props.listaAvaliacao.map(item => (
								<Grid cols='6 4 3 2'>
									<div class={`callout callout-${item.fumante ? 'danger' : 'success'}`}>
										<h6 style={{ textAlign: 'center' }}>{item.ano}</h6>
										<p style={{ textAlign: 'center' }}>{item.fumante ? 'Sim' : 'Não'}</p>
									</div>
								</Grid>
							))}
						</Row>
					</ContentCardBody>
				</ContentCard>
				<ContentCard>
					<ContentCardHeader>
						<h3 class='card-title'>Desconforto</h3>
					</ContentCardHeader>
					<ContentCardBody>
						<Row>
							<Grid cols='12 12 12 12'>
								<Table responsive style={{ padding: 8 }}>
									<TBody>
										{this.props.listaAvaliacao.map(item => (
											<Tr key={item.id}>
												<Td width={10} bold>{item.ano}</Td>
												<Td>
													<div style={{ display: 'flex', flexDirection: 'row' }}>
														{!item.desconfortos.filter(item => item.id_nivel > 1).length ? 'Nenhum' : item.desconfortos.filter(item => item.id_nivel > 1).map(item => (
															<div style={{ backgroundColor: item.id_nivel == 2 ? '#a4b526' : '#bf0404', color: '#ffffff', paddingTop: 4, paddingBottom: 4, paddingLeft: 8, paddingRight: 4, borderRadius: 4, margin: 2 }}>
																{item.nome}
															</div>
														))}
													</div>
												</Td>
											</Tr>
										))}
									</TBody>
								</Table>
							</Grid>
						</Row>
					</ContentCardBody>
				</ContentCard>
				<ContentCard>
					<ContentCardHeader>
						<h3 class='card-title'>Patologias</h3>
					</ContentCardHeader>
					<ContentCardBody>
						<Row>
							<Grid cols='12 12 12 12'>
								<Table responsive style={{ padding: 8 }}>
									<TBody>
										{this.props.listaAvaliacao.map(item => (
											<Tr key={item.id}>
												<Td width={10} bold>{item.ano}</Td>
												<Td>
													<div style={{ display: 'flex', flexDirection: 'row' }}>
														{!item.patologias.length ? 'Nenhuma' : item.patologias.map(item => (
															<div style={{ backgroundColor: '#005ca9', color: '#ffffff', paddingTop: 4, paddingBottom: 4, paddingLeft: 8, paddingRight: 4, borderRadius: 4, margin: 2 }}>
																{item.nome}
															</div>
														))}
													</div>
												</Td>
											</Tr>
										))}
									</TBody>
								</Table>
							</Grid>
						</Row>
					</ContentCardBody>
				</ContentCard>
				<ContentCard>
					<ContentCardHeader>
						<h3 class='card-title'>Exercício Físico / Esporte (semana)</h3>
					</ContentCardHeader>
					<ContentCardBody>
						<Row>
							<Grid cols='12 12 12 12'>
								<Table responsive style={{ padding: 8 }}>
									<TBody>
										{this.props.listaAvaliacao.map(item => (
											<Tr key={item.id}>
												<Td width={10} bold>{item.ano}</Td>
												<Td>
													<div style={{ display: 'flex', flexDirection: 'row' }}>
														{!item.exercicios.length ? 'Nenhum' : item.exercicios.map(item => (
															<div style={{ backgroundColor: '#005ca9', color: '#ffffff', paddingTop: 4, paddingBottom: 4, paddingLeft: 8, paddingRight: 4, borderRadius: 4, margin: 2 }}>
																{`${item.nome} (${item.quantidade || 1} ${item.quantidade > 1 ? 'vezes' : 'vez'})`}
															</div>
														))}
													</div>
												</Td>
											</Tr>
										))}
									</TBody>
								</Table>
							</Grid>
						</Row>
					</ContentCardBody>
				</ContentCard>
				<ContentCard>
					<ContentCardHeader>
						<h3 class='card-title'>Outras Atividades</h3>
					</ContentCardHeader>
					<ContentCardBody>
						<Row>
							<Grid cols='12 12 12 12'>
								<Table responsive style={{ padding: 8 }}>
									<TBody>
										{this.props.listaAvaliacao.map(item => (
											<Tr key={item.id}>
												<Td width={10} bold>{item.ano}</Td>
												<Td>
													<div style={{ display: 'flex', flexDirection: 'row' }}>
														{!item.atividades.length ? 'Nenhuma' : item.atividades.map(item => (
															<div style={{ backgroundColor: '#005ca9', color: '#ffffff', paddingTop: 4, paddingBottom: 4, paddingLeft: 8, paddingRight: 4, borderRadius: 4, margin: 2 }}>
																{item.nome}
															</div>
														))}
													</div>
												</Td>
											</Tr>
										))}
									</TBody>
								</Table>
							</Grid>
						</Row>
					</ContentCardBody>
				</ContentCard>
				<ContentCard>
					<ContentCardHeader>
						<h3 class='card-title'>Sugestões de Exercício Físico / Esporte (semana)</h3>
					</ContentCardHeader>
					<ContentCardBody>
						<Row>
							<Grid cols='12 12 12 12'>
								<Table responsive style={{ padding: 8 }}>
									<TBody>
										{this.props.listaAvaliacao.map(item => (
											<Tr key={item.id}>
												<Td width={10} bold>{item.ano}</Td>
												<Td>
													<div style={{ display: 'flex', flexDirection: 'row' }}>
														{!item.exercicio_recomendacoes.length ? 'Nenhum' : item.exercicio_recomendacoes.map(item => (
															<div style={{ backgroundColor: '#005ca9', color: '#ffffff', paddingTop: 4, paddingBottom: 4, paddingLeft: 8, paddingRight: 4, borderRadius: 4, margin: 2 }}>
																{`${item.nome} (${item.quantidade || 1} ${item.quantidade > 1 ? 'vezes' : 'vez'})`}
															</div>
														))}
													</div>
												</Td>
											</Tr>
										))}
									</TBody>
								</Table>
							</Grid>
						</Row>
					</ContentCardBody>
				</ContentCard>
				<ContentCard>
					<ContentCardHeader>
						<h3 class='card-title'>Recomendações</h3>
					</ContentCardHeader>
					<ContentCardBody>
						<Row>
							<Grid cols='12 12 12 12'>
								<Table responsive style={{ padding: 8 }}>
									<TBody>
										{this.props.listaAvaliacao.map(item => (
											<Tr key={item.id}>
												<Td width={10} bold>{item.ano}</Td>
												<Td>
													<div style={{ display: 'flex', flexDirection: 'row' }}>
														{!item.recomendacoes.length ? 'Nenhuma' : item.recomendacoes.map(item => (
															<div style={{ backgroundColor: '#005ca9', color: '#ffffff', paddingTop: 4, paddingBottom: 4, paddingLeft: 8, paddingRight: 4, borderRadius: 4, margin: 2 }}>
																{item.nome}
															</div>
														))}
													</div>
												</Td>
											</Tr>
										))}
									</TBody>
								</Table>
							</Grid>
						</Row>
					</ContentCardBody>
				</ContentCard>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	listaCliente: ClienteUtils.filtrarLista(state.cliente.listaSelect.map(item => ({ ...item, id_cliente: item.id })), state.auth.usuario),
	listaUsuario: state.usuario.listaSelect,
	listaAvaliacao: state.resultadoIndividual.listaAvaliacao
});
const mapDispatchToProps = dispatch => bindActionCreators({ 
	getListaCliente, getListaUsuario, getListaAvaliacao
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ResultadoIndividual);