const INITIAL_STATE = {
    registro: {},
    modoTela: 'lista',
    lista: [],
    listaSelect: [],
    listaPerfil: [],
    listaCliente: [],
    listaUnidade: [],
    listaSetor: [],
    listaCargo: [],
    listaSexo: [{ id: 1, valor: 'Feminino' }, { id: 2, valor: 'Masculino' }]
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'USUARIO_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

        case 'USUARIO_LISTADO':
            return {
                ...state, 
                lista: action.payload.data,
                listaSelect: action.payload.data.map(item => ({
                    id: item.id,
                    valor: `${item.nome}${item.data_desligamento ? ' (Inativo)' : ''}`,
                    id_cliente: item.id_cliente,
                    id_sexo: item.id_sexo,
                    idade: item.idade,
                    inativo: item.data_desligamento ? true : false
                }))
            };

        case 'USUARIO_PERFIL_SELECT_LISTADO':
			return {
				...state,
				listaPerfil: action.payload.data
			};
           
        case 'USUARIO_CLIENTE_SELECT_LISTADO':
            return {
                ...state,
                listaCliente: action.payload.data
            };

        case 'USUARIO_UNIDADE_SELECT_LISTADO':
            return {
                ...state,
                listaUnidade: action.payload.data
            };

        case 'USUARIO_SETOR_SELECT_LISTADO':
            return {
                ...state,
                listaSetor: action.payload.data
            };

        case 'USUARIO_CARGO_SELECT_LISTADO':
			return {
				...state,
				listaCargo: action.payload.data
			};

        default:
            return state;
    }
}
