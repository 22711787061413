import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import Select from '../common/form/select';
import SelectDefault from '../common/form/selectDefault';

import { setModoTela, initForm } from './usuarioActions';
import labelAndInputMask from '../common/form/labelAndInputMask';
import ClienteUtils from '../common/utils/ClienteUtils';

class UsuarioForm extends Component {

    componentWillMount() {

    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

        return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<ContentCardBody>
						<Row>
							<Field
								name='nome'
								component={LabelAndInput}
								label='Nome *' placeholder='Informe o Nome'
								cols='12 12 12 12'
								readOnly={readOnly} />

							<Field
								name='email'
								component={LabelAndInput}
								label='E-mail *'
								placeholder='Informe o E-mail'
								cols='12 12 12 12'
								readOnly={readOnly} />

							{!this.props.configuracao && (
								<Field
									name='senha'
									component={LabelAndInput}
									type="password"
									label='Senha *'
									placeholder='Informe a Senha'
									cols='12 12 12 12'
									readOnly={readOnly} />
							)}

							<Field
								name='id_perfil'
								component={Select}
								options={this.props.listaPerfil.filter(item => {
									if (this.props.colaborador) {
										return item.id_tipo == 3;
									} else if (this.props.professor) {
										return item.id_tipo == 2;
									} else {
										return item.id_tipo == 1 || item.id_tipo == 2;
									}
								})}
								label='Perfil'
								cols='12 12 12 12'
								placeholder='Selecione o perfil'
								readOnly={readOnly} />

							{this.props.colaborador &&
							<>
							
								<Select
									name='id_cliente'
									options={this.props.listaCliente}
									label='Cliente'
									cols='12 12 4 4'
									placeholder='Selecione o cliente'
									readOnly={this.props.formularioValues.id ? 'readOnly' : readOnly}
									value={this.props.formularioValues.id_cliente}
									onChange={value => {
										this.props.initForm({
											...this.props.formularioValues,
											id_cliente: value,
											id_unidade: null,
											id_setor: null
										});
									}} />
																
								<SelectDefault
									name='id_unidade'
									options={this.props.listaUnidade.filter(item => item.id_cliente == this.props.formularioValues.id_cliente)}
									label='Unidade'
									cols='12 12 4 4'
									placeholder='Selecione a unidade'
									readOnly={readOnly}
									value={this.props.formularioValues.id_unidade}
									onChange={data => {
										this.props.initForm({
											...this.props.formularioValues,
											id_unidade: data.target.value,
											id_setor: null
										});
									}} />
									
								<SelectDefault
									name='id_setor'
									options={this.props.listaSetor.filter(item => item.id_unidade == this.props.formularioValues.id_unidade)}
									label='Setor'
									cols='12 12 4 4'
									placeholder='Selecione o setor'
									readOnly={readOnly}
									value={this.props.formularioValues.id_setor}
									onChange={data => {
										this.props.initForm({
											...this.props.formularioValues,
											id_setor: data.target.value
										});
									}} />

								<Field
									name='id_cargo'
									component={Select}
									options={this.props.listaCargo.filter(item => item.id_cliente == this.props.formularioValues.id_cliente)}
									label='Cargo'
									cols='12 12 4 4'
									placeholder='Selecione o cargo'
									readOnly={readOnly} />
									
								<Field
									name='codigo'
									component={LabelAndInput}
									label='Código' placeholder='Informe o código'
									cols='12 12 4 4'
									readOnly={readOnly} />
									
								<Field
									name='id_sexo'
									component={Select}
									options={this.props.listaSexo}
									label='Sexo'
									cols='12 12 4 4'
									placeholder='Selecione o sexo'
									readOnly={readOnly} />
									
								<Field
									name='data_nascimento'
									component={labelAndInputMask}
									label='Data de Nascimento' placeholder='Informe a data'
									cols='12 4 4 4'
									readOnly={readOnly}
									mask='99/99/9999' />
									
								<Field
									name='data_admissao'
									component={labelAndInputMask}
									label='Data de Admissão' placeholder='Informe a data'
									cols='12 4 4 4'
									readOnly={readOnly}
									mask='99/99/9999' />
									
								<Field
									name='data_desligamento'
									component={labelAndInputMask}
									label='Data de Desligamento' placeholder='Informe a data'
									cols='12 4 4 4'
									readOnly={readOnly}
									mask='99/99/9999' />

							</>}
							
						</Row>
					</ContentCardBody>
					<ContentCardFooter>
						<Row alignCenter>
							<Grid cols='6 6 4 3'>
								{this.props.excluir ? (
									<Button
										text='Excluir'
										submit type={'danger'}
										icon={'fa fa-trash'} />
								) : (
								<Button
									text='Salvar'
									submit
									type={'success'}
									icon={'fa fa-check'} />
								)}
							</Grid>
							<Grid cols='6 6 4 3'>
								<Button
									text='Voltar'
									type={'warning'}
									icon={'fa fa-chevron-left'}
									event={() => this.props.setModoTela('lista')} />
							</Grid>
						</Row>
					</ContentCardFooter>

				</Form>
			</ContentCard>
        )
    }


}

UsuarioForm = reduxForm({form: 'usuarioForm', destroyOnUnmount: false})(UsuarioForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	formularioValues: getFormValues('usuarioForm')(state),
	registro: state.usuario.registro,
	listaPerfil: state.usuario.listaPerfil,
	listaCliente: ClienteUtils.filtrarLista(state.usuario.listaCliente.map(item => ({ ...item, id_cliente: item.id })), state.auth.usuario),
	listaUnidade: state.usuario.listaUnidade,
	listaSetor: state.usuario.listaSetor,
	listaCargo: state.usuario.listaCargo,
	listaSexo: state.usuario.listaSexo
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(UsuarioForm);
