import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './avaliacaoForm';
import { setError } from '../common/toast/toast';
import readXlsxFile from 'read-excel-file/web-worker'
import Aguardando from '../common/template/aguardando';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';

import { 
	setModoTela, initForm, salvar, excluir, getLista, imprimirPlanilhaBioimpedancia, getListaStatus
} from './avaliacaoActions';
import { 
	getLista as getListaCliente
} from '../cliente/clienteActions';
import { 
	getLista as getListaUnidade
} from '../unidade/unidadeActions';
import { 
	getLista as getListaSetor
} from '../setor/setorActions';
import { 
	getLista as getListaUsuario
} from '../usuario/usuarioActions';
import { 
	getLista as getListaPatologia
} from '../patologia/patologiaActions';
import { 
	getLista as getListaExercicio
} from '../exercicio/exercicioActions';
import { 
	getLista as getListaAtividade
} from '../atividade/atividadeActions';
import { 
	getLista as getListaRecomendacao
} from '../recomendacao/recomendacaoActions';
import { 
	getLista as getListaDesconforto
} from '../desconforto/desconfortoActions';
import ClienteUtils from '../common/utils/ClienteUtils';
import Select from '../common/form/select';

class Avaliacao extends Component {

	state = {
		linhaSelecionada: null,
		paginacaoIndice: 0,
		filtro: {
			ano: new Date().getFullYear()
		}
	}

	componentDidMount() {

	}

    componentWillMount() {
        this.props.getLista();
		this.props.getListaStatus();
		this.props.getListaCliente();
		this.props.getListaUnidade();
		this.props.getListaSetor();
		this.props.getListaUsuario();
		this.props.getListaPatologia();
		this.props.getListaExercicio();
		this.props.getListaAtividade();
		this.props.getListaRecomendacao();
		this.props.getListaDesconforto();
    }

    render() {
        return (
            <div>
                <Content>
					<Aguardando aguardando={this.props.aguardando} />

					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
                </Content>
            </div>
        )
    }

	lista() {
		
		let lista = (this.props.lista).filter(item => {
			if (this.state.pesquisar
				&& !(`
					${item.nome_usuario} ${item.ano} ${item.nome_status} ${item.nome_cliente}
					${item.nome_unidade || ''} ${item.nome_setor || ''} ${item.nome_cargo || ''}
				`).toUpperCase().includes(this.state.pesquisar.toUpperCase())) {
				return false;
			}
			if (this.state.filtro.id_cliente && item.id_cliente != this.state.filtro.id_cliente) {
				return false;
			}
			if (this.state.filtro.id_unidade && item.id_unidade != this.state.filtro.id_unidade) {
				return false;
			}
			if (this.state.filtro.id_setor && item.id_setor != this.state.filtro.id_setor) {
				return false;
			}
			if (this.state.filtro.ano && item.ano != this.state.filtro.ano) {
				return false;
			}
			return true;
		});

		let paginacao = [];

		if (this.props.lista.length) {
			for (let i = 0; i < this.props.lista.length; i += 500) {				
				paginacao.push({
					inicio: i,
					fim: i + 499
				});				
			}
		} else {
			paginacao.push({
				inicio: 0,
				fim: 499
			});
		}

		return (
			<ContentCard>
				<ContentCardHeader>
					<Row>
						<Grid cols='6 6 4 2'>
							<Button
								text='Adicionar'
								type={'success'}
								icon={'fa fa-plus'}
								event={() => {
									this.props.setModoTela('cadastro', {});
									this.props.initForm({ 
										data_avaliacao: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAtual()), 
										ano: new Date().getFullYear(),
										id_status: 1 //Realizado
									});
								}} />
						</Grid>
						
						{(this.state.filtro.id_cliente && this.state.filtro.id_unidade && this.state.filtro.id_setor) &&
						<Grid cols='6 6 4 3'>
							<Button
								text='Planilha Bioimpedância'
								type={'primary'}
								icon={'fa fa-chevron-left'}
								event={() => {
									this.props.imprimirPlanilhaBioimpedancia(this.state.filtro);
								}} />
						</Grid>}
					</Row>
				</ContentCardHeader>
				<ContentCardBody>

					<Row>
						<Select
							options={this.props.listaCliente}
							label='Cliente'
							cols='12 3 3 3'
							placeholder='Selecione'
							value={this.state.filtro.id_cliente}
							onChange={value => {
								this.atualizarFiltro({
									...this.state.filtro,
									id_cliente: value	
								});
							}} />

						<Select 
							options={this.props.listaUnidade.filter(item => this.state.filtro.id_cliente && item.id_cliente == this.state.filtro.id_cliente)}
							label='Unidade'
							cols='12 4 4 4'
							placeholder='Selecione'
							value={this.state.filtro.id_unidade}
							onChange={value => {
								this.atualizarFiltro({
									...this.state.filtro,
									id_unidade: value	
								});
							}} />

						<Select 
							options={this.props.listaSetor.filter(item => this.state.filtro.id_unidade && item.id_unidade == this.state.filtro.id_unidade)}
							label='Setor'
							cols='12 3 3 3'
							placeholder='Selecione'
							value={this.state.filtro.id_setor}
							onChange={value => {
								this.atualizarFiltro({
									...this.state.filtro,
									id_setor: value	
								});
							}} />

						<Select
							options={Array.from(Array(10).keys()).map(item => ({ id: item + 2018, valor: String(item + 2018).padStart(2, '0') }))}
							label='Ano'
							cols='12 6 3 2'
							placeholder='Selecione o ano'
							value={this.state.filtro.ano}
							onChange={value => {
								this.atualizarFiltro({
									...this.state.filtro,
									ano: value	
								});
							}} />
					</Row>
										
					<ul class='pagination pagination-sm m-0 float-right'>
						<li class='page-item'>
							<a class='page-link' 
								style={{ 
									cursor: 'pointer'
								}}
								onClick={() => this.setState({
									...this.state,
									paginacaoIndice: this.state.paginacaoIndice > 0 ? this.state.paginacaoIndice - 1 : this.state.paginacaoIndice
								})}>{'<<'}
							</a>
						</li>
						
						{paginacao.map((item, i) => (
							<li class='page-item'>
								<a class='page-link'  
									style={{ 
										fontWeight: 'bold',
										cursor: 'pointer',
										backgroundColor: i == this.state.paginacaoIndice ? '#007bff' : null,
										color: i == this.state.paginacaoIndice ? '#fff' : null
									}}
									onClick={() => this.setState({
										...this.state,
										paginacaoIndice: i
									})}>{i + 1}
								</a>
							</li>
						))}
						
						<li class='page-item'>
							<a class='page-link' 
								style={{ 
									cursor: 'pointer'
								}}
								onClick={() => this.setState({
									...this.state,
									paginacaoIndice: paginacao.length - 1 > this.state.paginacaoIndice ? this.state.paginacaoIndice + 1 : this.state.paginacaoIndice
								})}>{'>>'}
							</a>
						</li>
					</ul>

					<input className='form-control'
						 placeholder='Pesquisar'
						 type='text'
						 value={this.state.pesquisar}
						 onChange={e => {
							 this.setState({ ...this.state, pesquisar: e.target.value, paginacaoIndice: 0 });
						}} />

					<Table responsive>
						<THead>
							<Tr>
								<Th>Colaborador</Th>
								<Th alignCenter>Ano</Th>
								<Th alignCenter>Status</Th>
								<Th alignCenter>Cliente</Th>
								<Th alignCenter>Unidade</Th>
								<Th alignCenter>Setor</Th>
								<Th alignCenter>Cargo</Th>
								<Th></Th>
							</Tr>
						</THead>
						<TBody>
							{lista.filter((item, i) => {
								return i >= paginacao[this.state.paginacaoIndice].inicio && i <= paginacao[this.state.paginacaoIndice].fim;
							}).map(item => (
								<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
									onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
									onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
									<Td>{item.nome_usuario}</Td>
									<Td alignCenter>{item.ano}</Td>
									<Td alignCenter backgroundColor><span style={{ fontSize: 12, padding: 6 }} class={`badge bg-${item.id_status == 1 ? 'success' : 'warning'}`}>{item.nome_status}</span></Td>
									<Td alignCenter>{item.nome_cliente}</Td>
									<Td alignCenter>{item.nome_unidade}</Td>
									<Td alignCenter>{item.nome_setor}</Td>
									<Td alignCenter>{item.nome_cargo}</Td>
									<Td alignRight minWidth={10}>																			
										<ButtonTable
											type={'warning'}
											icon={'fas fa-pencil-alt'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.setModoTela('cadastro', {
													...item,
													peso: FormatUtils.formatarValorTela(item.peso, 2),
													imc: FormatUtils.formatarValorTela(item.imc, 2),
													gordura_corporal: FormatUtils.formatarValorTela(item.gordura_corporal, 2),
													gordura_visceral: FormatUtils.formatarValorTela(item.gordura_visceral, 2),
													musculo_esqueletico: FormatUtils.formatarValorTela(item.musculo_esqueletico, 2),
													metabolismo_basal: FormatUtils.formatarValorTela(item.metabolismo_basal, 2),
													idade_corporal: FormatUtils.formatarValorTela(item.idade_corporal, 2),
													altura: FormatUtils.formatarValorTela(item.altura, 2),
													pratica_exercicio_media: FormatUtils.formatarValorTela(item.pratica_exercicio_media || 0, 0),
												});
												this.props.initForm({
													...item,
													peso: FormatUtils.formatarValorTela(item.peso, 2),
													imc: FormatUtils.formatarValorTela(item.imc, 2),
													gordura_corporal: FormatUtils.formatarValorTela(item.gordura_corporal, 2),
													gordura_visceral: FormatUtils.formatarValorTela(item.gordura_visceral, 2),
													musculo_esqueletico: FormatUtils.formatarValorTela(item.musculo_esqueletico, 2),
													metabolismo_basal: FormatUtils.formatarValorTela(item.metabolismo_basal, 2),
													idade_corporal: FormatUtils.formatarValorTela(item.idade_corporal, 2),
													altura: FormatUtils.formatarValorTela(item.altura, 2),
													pratica_exercicio_media: FormatUtils.formatarValorTela(item.pratica_exercicio_media || 0, 0),
												});
											}} />

										<ButtonTable
											type={'danger'}
											icon={'fas fa-trash-alt'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.setModoTela('exclusao', {
													...item,
													peso: FormatUtils.formatarValorTela(item.peso, 2),
													imc: FormatUtils.formatarValorTela(item.imc, 2),
													gordura_corporal: FormatUtils.formatarValorTela(item.gordura_corporal, 2),
													gordura_visceral: FormatUtils.formatarValorTela(item.gordura_visceral, 2),
													musculo_esqueletico: FormatUtils.formatarValorTela(item.musculo_esqueletico, 2),
													metabolismo_basal: FormatUtils.formatarValorTela(item.metabolismo_basal, 2),
													idade_corporal: FormatUtils.formatarValorTela(item.idade_corporal, 2),
													altura: FormatUtils.formatarValorTela(item.altura, 2),
													pratica_exercicio_media: FormatUtils.formatarValorTela(item.pratica_exercicio_media || 0, 0),
												});
												this.props.initForm({
													...item,
													peso: FormatUtils.formatarValorTela(item.peso, 2),
													imc: FormatUtils.formatarValorTela(item.imc, 2),
													gordura_corporal: FormatUtils.formatarValorTela(item.gordura_corporal, 2),
													gordura_visceral: FormatUtils.formatarValorTela(item.gordura_visceral, 2),
													musculo_esqueletico: FormatUtils.formatarValorTela(item.musculo_esqueletico, 2),
													metabolismo_basal: FormatUtils.formatarValorTela(item.metabolismo_basal, 2),
													idade_corporal: FormatUtils.formatarValorTela(item.idade_corporal, 2),
													altura: FormatUtils.formatarValorTela(item.altura, 2),
													pratica_exercicio_media: FormatUtils.formatarValorTela(item.pratica_exercicio_media || 0, 0),
												});
											}} />
									</Td>
								</Tr>
							))}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}

	atualizarFiltro(filtro) {
		this.setState({
			...this.state,
			filtro: { ...filtro }
		});
	}
}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.avaliacao.modoTela,
	aguardando: state.avaliacao.aguardando,
	lista: ClienteUtils.filtrarLista(state.avaliacao.lista, state.auth.usuario),
	listaCliente: ClienteUtils.filtrarLista(state.cliente.listaSelect.map(item => ({ ...item, id_cliente: item.id })), state.auth.usuario),
	listaUnidade: state.unidade.listaSelect,
	listaSetor: state.setor.listaSelect
});
const mapDispatchToProps = dispatch => bindActionCreators({ 
	setModoTela, initForm, salvar, excluir, getLista,
	getListaCliente, getListaUsuario, getListaUnidade, getListaSetor,
	getListaPatologia, getListaExercicio, getListaAtividade, getListaRecomendacao, getListaDesconforto, imprimirPlanilhaBioimpedancia, getListaStatus
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Avaliacao);