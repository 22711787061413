import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import Select from '../common/form/select';
import SelectDefault from '../common/form/selectDefault';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';

import {
	setModoTela, initForm, salvar,
	getListaAvaliacaoPatologia, salvarPatologia,
	getListaAvaliacaoExercicio, salvarExercicio,
	getListaAvaliacaoAtividade, salvarAtividade,
	getListaAvaliacaoExercicioRecomendacao, salvarExercicioRecomendacao,
	getListaAvaliacaoRecomendacao, salvarRecomendacao,
	getListaAvaliacaoDesconforto, salvarDesconforto,
	getListaMeta, salvarMeta
} from './avaliacaoActions';
import FormatUtils from '../common/formatUtils/FormatUtils';
import ClienteUtils from '../common/utils/ClienteUtils';

class AvaliacaoForm extends Component {

	state = {

	}

	componentDidMount() {

		this.props.getListaAvaliacaoPatologia(this.props.formularioValues);
		this.props.getListaAvaliacaoExercicio(this.props.formularioValues);
		this.props.getListaAvaliacaoAtividade(this.props.formularioValues);
		this.props.getListaAvaliacaoExercicioRecomendacao(this.props.formularioValues);
		this.props.getListaAvaliacaoRecomendacao(this.props.formularioValues);
		this.props.getListaAvaliacaoDesconforto(this.props.formularioValues);
		this.props.getListaMeta(this.props.formularioValues);

	}

	render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

		return (
			<Form event={this.props.handleSubmit}>
				<ContentCard>
					<ContentCardBody>
						<Row>

							<Field
								name='id_cliente'
								component={Select}
								options={this.props.listaCliente}
								label='Cliente'
								cols='12 3 3 3'
								placeholder='Selecione o cliente'
								readOnly={this.props.formularioValues.id ? 'readOnly' : readOnly} />

							<Field
								name='id_usuario'
								component={Select}
								options={this.props.listaUsuario.filter(item => 
									this.props.formularioValues.id_cliente && item.id_cliente == this.props.formularioValues.id_cliente
									&& (this.props.formularioValues.id || !item.inativo) //Se é alteração ou está ativo
								)}
								label='Colaborador'
								cols='12 4 4 4'
								placeholder='Selecione o colaborador'
								readOnly={this.props.formularioValues.id ? 'readOnly' : readOnly} />

							<Field
								name='ano'
								component={LabelAndInputMask}
								label='Ano' placeholder='Informe o ano'
								cols='12 2 2 2'
								readOnly={this.props.formularioValues.id ? 'readOnly' : readOnly}
								mask='9999' />
								
							<Field
								name='id_status'
								component={Select}
								options={this.props.listaStatus}
								label='Status'
								cols='12 3 3 3'
								placeholder='Selecione o status'
								readOnly={readOnly} />
						</Row>
					</ContentCardBody>
					{this.props.formularioValues.id ? (
					<ContentCardFooter>
						<Row alignCenter>
							<Grid cols='6 6 4 3'>
								<Button
									text='Salvar'
									type={'success'}
									icon={'fa fa-check'}
									event={() => this.props.salvar(this.props.formularioValues, true)} />
							</Grid>
						</Row>
					</ContentCardFooter>) : null}
				</ContentCard>

				{this.balanca(readOnly)}

				{this.desconforto(readOnly)}

				{this.patologia(readOnly)}

				{this.props.formularioValues.id ? this.fumante(readOnly) : null}

				{this.exercicio(readOnly)}

				{this.props.formularioValues.id ? this.praticaExercicio(readOnly) : null}

				{this.atividade(readOnly)}

				{this.exercicioRecomendacao(readOnly)}

				{this.recomendacao(readOnly)}

				{this.meta(readOnly)}

				<ContentCard>
					<ContentCardFooter>
						<Row alignCenter>
							<Grid cols='6 6 4 3'>
								{this.props.excluir ? (
									<Button
										text='Excluir'
										submit type={'danger'}
										icon={'fa fa-trash'} />
								) : (
									<Button
										text='Salvar'
										submit
										type={'success'}
										icon={'fa fa-check'} />
								)}
							</Grid>
							<Grid cols='6 6 4 3'>
								<Button
									text='Voltar'
									type={'warning'}
									icon={'fa fa-chevron-left'}
									event={() => this.props.setModoTela('lista')} />
							</Grid>
						</Row>
					</ContentCardFooter>
				</ContentCard>
			</Form>
		);
	}

	balanca(readOnly) {

		return (
			<ContentCard>
				<ContentCardHeader>
					<h3 class='card-title'>Balança de Bioimpedância</h3>
				</ContentCardHeader>
				<ContentCardBody>
					<Row>
						<Field
							name='peso'
							component={LabelAndInputNumber}
							label='Peso (kg)' placeholder='Informe o peso'
							cols='12 6 6 6'
							readOnly={readOnly}
							casas={2} />

						<Field
							name='altura'
							component={LabelAndInputNumber}
							label='Altura (m)' placeholder='Informe a altura'
							cols='12 6 6 6'
							readOnly={readOnly}
							casas={2} />

						<Field
							name='imc'
							component={LabelAndInputNumber}
							label='IMC' placeholder='Informe o IMC'
							cols='12 4 4 4'
							readOnly={readOnly}
							casas={2}
							value={this.props.formularioValues.imc}
							onChange={data => {
								this.props.initForm({
									...this.props.formularioValues,
									imc: data.target.value,
									imc_classificacao: this.getClassificacao('IMC', parseFloat(FormatUtils.formatarValorBanco(data.target.value, 1) || 0))
								});
							}} />

						<Field
							name='imc_classificacao'
							component={LabelAndInput}
							label='Classificação IMC' placeholder='Informe a classificação'
							cols='12 8 8 8'
							readOnly={'readOnly'} />

						<Field
							name='gordura_corporal'
							component={LabelAndInputNumber}
							label='Gordura Corporal' placeholder='Informe a gordura corporal'
							cols='12 4 4 4'
							readOnly={readOnly}
							casas={2}
							value={this.props.formularioValues.gordura_corporal}
							onChange={data => {
								this.props.initForm({
									...this.props.formularioValues,
									gordura_corporal: data.target.value,
									gordura_corporal_descricao: this.getClassificacao('GORDURA_CORPORAL', parseFloat(FormatUtils.formatarValorBanco(data.target.value, 1) || 0))
								});
							}} />

						<Field
							name='gordura_corporal_descricao'
							component={LabelAndInput}
							label='Descrição' placeholder='Informe a descrição'
							cols='12 8 8 8'
							readOnly={'readOnly'} />

						<Field
							name='gordura_visceral'
							component={LabelAndInputNumber}
							label='Gordura Visceral' placeholder='Informe a gordura visceral'
							cols='12 4 4 4'
							readOnly={readOnly}
							casas={2}
							value={this.props.formularioValues.gordura_visceral}
							onChange={data => {
								this.props.initForm({
									...this.props.formularioValues,
									gordura_visceral: data.target.value,
									gordura_visceral_descricao: this.getClassificacao('GORDURA_VISCERAL', parseFloat(FormatUtils.formatarValorBanco(data.target.value, 1) || 0))
								});
							}} />

						<Field
							name='gordura_visceral_descricao'
							component={LabelAndInput}
							label='Descrição' placeholder='Informe a descrição'
							cols='12 8 8 8'
							readOnly={'readOnly'} />

						<Field
							name='musculo_esqueletico'
							component={LabelAndInputNumber}
							label='Músculo Esquelético' placeholder='Informe o músculo esquelético'
							cols='12 4 4 4'
							readOnly={readOnly}
							casas={2}
							value={this.props.formularioValues.musculo_esqueletico}
							onChange={data => {
								this.props.initForm({
									...this.props.formularioValues,
									musculo_esqueletico: data.target.value,
									musculo_esqueletico_descricao: this.getClassificacao('MUSCULO_ESQUELETICO', parseFloat(FormatUtils.formatarValorBanco(data.target.value, 1) || 0))
								});
							}} />

						<Field
							name='musculo_esqueletico_descricao'
							component={LabelAndInput}
							label='Descrição' placeholder='Informe a descrição'
							cols='12 8 8 8'
							readOnly={'readOnly'} />

						<Field
							name='metabolismo_basal'
							component={LabelAndInputNumber}
							label='Metabolismo Basal' placeholder='Informe o metabolismo basal'
							cols='12 4 4 4'
							readOnly={readOnly}
							casas={2} />

					</Row>
					<Row>
						
						<Field
							name='idade_corporal'
							component={LabelAndInputNumber}
							label='Idade Corporal' placeholder='Informe a idade corporal'
							cols='12 4 4 4'
							readOnly={readOnly}
							casas={0}
							value={this.props.formularioValues.idade_corporal}
							onChange={data => {
								this.props.initForm({
									...this.props.formularioValues,
									idade_corporal: data.target.value,
									idade_corporal_descricao: this.getClassificacao('IDADE_CORPORAL', parseFloat(FormatUtils.formatarValorBanco(data.target.value, 0) || 0))
								});
							}} />

						<Field
							name='idade_corporal_descricao'
							component={LabelAndInput}
							label='Descrição' placeholder='Informe a descrição'
							cols='12 8 8 8'
							readOnly={'readOnly'} />

					</Row>

				</ContentCardBody>
				{this.props.formularioValues.id ? (
				<ContentCardFooter>
					<Row alignCenter>
						<Grid cols='6 6 4 3'>
							<Button
								text='Salvar'
								type={'success'}
								icon={'fa fa-check'}
								event={() => this.props.salvar(this.props.formularioValues, true)} />
						</Grid>
					</Row>
				</ContentCardFooter>) : null}
			</ContentCard>
		)
	}

	getClassificacao(tipo, valor) {

		const usuario = this.props.listaUsuario.filter(item => item.id == this.props.formularioValues.id_usuario)[0];

		if (usuario) {

			const sexo = usuario.id_sexo || 1;
			const idade = usuario.idade || 1;

			if (tipo == 'IMC') {
				const registro = this.props.listaImc.filter(item => item.inicio <= valor && item.fim >= valor)[0];
				return registro ? registro.descricao : '';
			} else if (tipo == 'GORDURA_CORPORAL') {
				const registro = this.props.listaGorduraCorporal.filter(item => 
					item.sexo == sexo
					&& item.idadeInicio <= idade && item.idadeFim >= idade
					&& item.inicio <= valor && item.fim >= valor
				)[0];
				return registro ? registro.descricao : '';
			} else if (tipo == 'GORDURA_VISCERAL') {
				const registro = this.props.listaGorduraVisceral.filter(item => item.inicio <= valor && item.fim >= valor)[0];
				return registro ? registro.descricao : '';
			} else if (tipo == 'MUSCULO_ESQUELETICO') {
				const registro = this.props.listaMusculoEsqueletico.filter(item => 
					item.sexo == sexo
					&& item.idadeInicio <= idade && item.idadeFim >= idade
					&& item.inicio <= valor && item.fim >= valor
				)[0];
				return registro ? registro.descricao : '';
			} else if (tipo == 'IDADE_CORPORAL') {
				const registro = this.props.listaIdadeCorporal.filter(item => item.inicio <= valor && item.fim >= valor)[0];
				return registro ? registro.descricao : '';
			}
		}

		return '';
	}

	patologia(readOnly) {

		if (!this.props.formularioValues.id) {
			return null;
		}

		return (
			<ContentCard>
				<ContentCardHeader>
					<h3 class='card-title'>Patologia</h3>
				</ContentCardHeader>
				<ContentCardBody>
					<Row>

						{this.props.listaPatologia.map(item => {
							let registroSalvo = this.props.listaAvaliacaoPatologia.filter(registro => registro.id_patologia == item.id)[0];

							return (
								<Select
									options={this.props.listaSimNao}
									label={item.valor}
									cols='12 6 4 3'
									placeholder='Selecione'
									readOnly={readOnly}
									value={registroSalvo ? 1 : 0}
									onChange={value => {
										if (registroSalvo || value == 1) {
											this.props.salvarPatologia({
												...(registroSalvo || {}),
												id_avaliacao: this.props.formularioValues.id,
												id_patologia: item.id,
												ativo: value == 1
											});
										}
									}} />
							);
						})}

					</Row>

				</ContentCardBody>
			</ContentCard>
		)
	}

	fumante(readOnly) {

		return (
			<ContentCard>
				<ContentCardHeader>
					<h3 class='card-title'>Gerais</h3>
				</ContentCardHeader>
				<ContentCardBody>
					<Row>

						<Field
							name='fumante'
							component={LabelAndCheckbox}
							label='Fumante' placeholder='Informe'
							cols='12 4 4 4'
							readOnly={readOnly} />

					</Row>

				</ContentCardBody>
				<ContentCardFooter>
					<Row alignCenter>
						<Grid cols='6 6 4 3'>
							<Button
								text='Salvar'
								type={'success'}
								icon={'fa fa-check'}
								event={() => this.props.salvar(this.props.formularioValues, true)} />
						</Grid>
					</Row>
				</ContentCardFooter>
			</ContentCard>
		)
	}

	exercicio(readOnly) {

		if (!this.props.formularioValues.id) {
			return null;
		}

		return (
			<ContentCard>
				<ContentCardHeader>
					<h3 class='card-title'>Prática de Exercício Físico / Esporte</h3>
				</ContentCardHeader>
				<ContentCardBody>
					<Row>

						{this.props.listaExercicio.map(item => {
							let registroSalvo = this.props.listaAvaliacaoExercicio.filter(registro => registro.id_exercicio == item.id)[0];

							return (
								<Select
									options={this.props.listaSimNaoQuantidade}
									label={item.valor}
									cols='12 6 4 3'
									placeholder='Selecione'
									readOnly={readOnly}
									value={registroSalvo ? registroSalvo.quantidade || 1 : 0}
									onChange={value => {
										if (registroSalvo || value >= 1) {
											this.props.salvarExercicio({
												...(registroSalvo || {}),
												id_avaliacao: this.props.formularioValues.id,
												id_exercicio: item.id,
												quantidade: value || 0,
												ativo: value >= 1
											});
										}
									}} />
							);
						})}

					</Row>

				</ContentCardBody>
			</ContentCard>
		)
	}

	praticaExercicio(readOnly) {

		return (
			<ContentCard>
				<ContentCardHeader>
					<h3 class='card-title'>Gerais</h3>
				</ContentCardHeader>
				<ContentCardBody>
					<Row>

						<Field
							name='pratica_exercicio'
							component={LabelAndCheckbox}
							label='Pratica Exercício' placeholder='Informe'
							cols='12 4 4 4'
							readOnly={readOnly} />

						<Field
							name='pratica_exercicio_media'
							component={Select}
							options={[
								{ id: 0, valor: '0' }, { id: 1, valor: '1' }, { id: 2, valor: '2' }, { id: 3, valor: '3' }, 
								{ id: 4, valor: '4' }, { id: 5, valor: '5' }, { id: 6, valor: '6' }, { id: 7, valor: '7' }
							]}
							label='Média de Exercícios'
							cols='12 4 4 4'
							placeholder='Selecione'
							readOnly={readOnly} />

					</Row>

				</ContentCardBody>
				<ContentCardFooter>
					<Row alignCenter>
						<Grid cols='6 6 4 3'>
							<Button
								text='Salvar'
								type={'success'}
								icon={'fa fa-check'}
								event={() => this.props.salvar(this.props.formularioValues, true)} />
						</Grid>
					</Row>
				</ContentCardFooter>
			</ContentCard>
		)
	}

	atividade(readOnly) {

		if (!this.props.formularioValues.id) {
			return null;
		}

		return (
			<ContentCard>
				<ContentCardHeader>
					<h3 class='card-title'>Outras Atividades</h3>
				</ContentCardHeader>
				<ContentCardBody>
					<Row>

						{this.props.listaAtividade.map(item => {
							let registroSalvo = this.props.listaAvaliacaoAtividade.filter(registro => registro.id_atividade == item.id)[0];

							return (
								<Select
									options={this.props.listaSimNao}
									label={item.valor}
									cols='12 6 4 3'
									placeholder='Selecione'
									readOnly={readOnly}
									value={registroSalvo ? 1 : 0}
									onChange={value => {
										if (registroSalvo || value == 1) {
											this.props.salvarAtividade({
												...(registroSalvo || {}),
												id_avaliacao: this.props.formularioValues.id,
												id_atividade: item.id,
												ativo: value == 1
											});
										}
									}} />
							);
						})}

					</Row>

				</ContentCardBody>
			</ContentCard>
		)
	}

	desconforto(readOnly) {

		if (!this.props.formularioValues.id) {
			return null;
		}

		return (
			<ContentCard>
				<ContentCardHeader>
					<h3 class='card-title'>Desconfortos</h3>
				</ContentCardHeader>
				<ContentCardBody>
					{this.props.listaAvaliacaoDesconforto.length > 0 &&
					<Row>

						{this.props.listaDesconforto.map(item => {
							let registroSalvo = this.props.listaAvaliacaoDesconforto.filter(registro => registro.id_desconforto == item.id)[0];

							return (
								<Select
									options={this.props.listaNivel}
									label={item.valor}
									cols='12 6 4 3'
									placeholder='Selecione'
									readOnly={readOnly}
									value={registroSalvo ? registroSalvo.id_nivel : 1}
									onChange={value => {
										if (registroSalvo || value >= 1) {
											this.props.salvarDesconforto({
												...(registroSalvo || {}),
												id_avaliacao: this.props.formularioValues.id,
												id_desconforto: item.id,
												id_nivel: value,
												ativo: value >= 1
											});
										}
									}} />
							);
						})}

					</Row>}

				</ContentCardBody>
			</ContentCard>
		)
	}

	exercicioRecomendacao(readOnly) {

		if (!this.props.formularioValues.id) {
			return null;
		}

		return (
			<ContentCard>
				<ContentCardHeader>
					<h3 class='card-title'>Sugestões / Recomendações</h3>
				</ContentCardHeader>
				<ContentCardBody>
					<Row>

						{this.props.listaExercicio.map(item => {
							let registroSalvo = this.props.listaAvaliacaoExercicioRecomendacao.filter(registro => registro.id_exercicio == item.id)[0];

							return (
								<Select
									options={this.props.listaSimNaoQuantidade}
									label={item.valor}
									cols='12 6 4 3'
									placeholder='Selecione'
									readOnly={readOnly}
									value={registroSalvo ? registroSalvo.quantidade || 1 : 0}
									onChange={value => {
										if (registroSalvo || value >= 1) {
											this.props.salvarExercicioRecomendacao({
												...(registroSalvo || {}),
												id_avaliacao: this.props.formularioValues.id,
												id_exercicio: item.id,
												quantidade: value || 0,
												ativo: value >= 1
											});
										}
									}} />
							);
						})}

					</Row>

				</ContentCardBody>
			</ContentCard>
		)
	}

	recomendacao(readOnly) {

		if (!this.props.formularioValues.id) {
			return null;
		}

		return (
			<ContentCard>
				<ContentCardHeader>
					<h3 class='card-title'>Outras Recomendações</h3>
				</ContentCardHeader>
				<ContentCardBody>
					<Row>

						{this.props.listaRecomendacao.map(item => {
							let registroSalvo = this.props.listaAvaliacaoRecomendacao.filter(registro => registro.id_recomendacao == item.id)[0];

							return (
								<Select
									options={this.props.listaSimNao}
									label={item.valor}
									cols='12 6 4 3'
									placeholder='Selecione'
									readOnly={readOnly}
									value={registroSalvo ? 1 : 0}
									onChange={value => {
										if (registroSalvo || value == 1) {
											this.props.salvarRecomendacao({
												...(registroSalvo || {}),
												id_avaliacao: this.props.formularioValues.id,
												id_recomendacao: item.id,
												ativo: value == 1
											});
										}
									}} />
							);
						})}

					</Row>

				</ContentCardBody>
			</ContentCard>
		)
	}

	meta(readOnly) {

		if (!this.props.formularioValues.id) {
			return null;
		}

		return (
			<ContentCard>

				{this.modalMeta()}

				<ContentCardHeader>
					<h3 class='card-title'>Metas</h3>
				</ContentCardHeader>
				<ContentCardBody>
					<Row>

						<LabelAndInput
							label={null} placeholder='Informe a meta'
							cols='12 4 4 4'
							readOnly={readOnly}
							value={this.state.meta}
							onChange={data => {
								this.setState({
									...this.state,
									meta: data.target.value
								});
							}} />

						<Grid cols='12 3 3 2'>
							<Button
								text='Adicionar'
								type={'primary'}
								event={() => {
									if (this.state.meta && this.state.meta.trim()) {
									
										this.props.salvarMeta({
											id_cliente: this.props.formularioValues.id_cliente,
											id_usuario: this.props.formularioValues.id_usuario,
											ano: this.props.formularioValues.ano,
											descricao: this.state.meta.trim()
										});

										this.setState({
											...this.state,
											meta: ''
										});
									}
								}} />
						</Grid>

						<Grid cols='12 12 12 12' style={{ padding: 8 }}>
							<Table responsive style={{ padding: 8 }}>
								<THead>
									<Tr>
										<Th>Ano</Th>
										<Th>Descrição</Th>
										<Th alignCenter>Atingiu</Th>
										<Th></Th>
									</Tr>
								</THead>
								<TBody>
									{this.props.listaMeta.map(item => (
										<Tr key={item.id}>
											<Td bold>{item.ano}</Td>
											<Td>{item.descricao}</Td>
											<Td alignCenter minWidth={10}>																			
												<ButtonTable
													type={item.atingiu ? 'success' : 'danger'}
													icon={item.atingiu ? 'fas fa-check' : 'fas fa-square'}
													visible={true}
													event={() => {
														this.props.salvarMeta({
															...item,
															atingiu: !item.atingiu
														});
													}} />
											</Td>
											<Td alignRight minWidth={60}>																			
												{item.ano == this.props.formularioValues.ano &&
												<ButtonTable
													type={'warning'}
													icon={'fas fa-pencil-alt'}
													visible={true}
													event={() => {
														this.setDialogMeta({ ...item });
													}} />}
												
												{item.ano == this.props.formularioValues.ano &&
												<ButtonTable
													type={'danger'}
													icon={'fas fa-trash-alt'}
													visible={true}
													event={() => {
														this.props.salvarMeta({
															...item,
															ativo: false
														});
													}} />}
											</Td>
										</Tr>
									))}
								</TBody>
							</Table>
						</Grid>

					</Row>

				</ContentCardBody>
			</ContentCard>
		)
	}

	setDialogMeta(meta) {
		this.setState({
			...this.state,
			registroMeta: meta
		});
	}

	modalMeta() {

		if (!this.state.registroMeta) {
			return null;
		}

        return (
			<div class='modal fade show' id='modal-xl' style={{ display: 'block' }} aria-modal='true'>
				<div class='modal-dialog modal-xl'>
					<div class='modal-content'>
						<div class='modal-header'>
							<h4 class='modal-title'>Alterar Meta</h4>
							<button type='button' class='close' data-dismiss='modal' aria-label='Close' onClick={() => this.setDialogMeta(null)}>
								<span aria-hidden='true'>×</span>
							</button>
						</div>
						<div class='modal-body'>
							<Row>
								<LabelAndInput
									label={null} placeholder='Informe a meta'
									cols='12 12 12 12'
									value={this.state.registroMeta.descricao}
									onChange={data => {
										this.setState({
											...this.state,
											registroMeta: {
												...this.state.registroMeta,
												descricao: data.target.value
											}
										});
									}} />
							</Row>
						</div>
						<div class='modal-footer justify-content-between'>
							<button type='button' class='btn btn-default' data-dismiss='modal' style={{ visibility: 'hidden' }}>Close</button>
							<button type='button' class='btn btn-primary'
								onClick={() => {
									this.props.salvarMeta({
										...this.state.registroMeta
									});
									this.setDialogMeta(null);
								}}>Salvar</button>
						</div>
					</div>
				</div>
			</div>
        );
    }

}

AvaliacaoForm = reduxForm({ form: 'avaliacaoForm', destroyOnUnmount: false })(AvaliacaoForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	formularioValues: getFormValues('avaliacaoForm')(state),
	registro: state.avaliacao.registro,
	listaStatus: state.avaliacao.listaStatus,
	listaItem: state.avaliacao.listaItem,
	listaCliente: ClienteUtils.filtrarLista(state.cliente.listaSelect.map(item => ({ ...item, id_cliente: item.id })), state.auth.usuario),
	listaUsuario: state.usuario.listaSelect,
	listaPatologia: state.patologia.listaSelect,
	listaExercicio: state.exercicio.listaSelect,
	listaAtividade: state.atividade.listaSelect,
	listaRecomendacao: state.recomendacao.listaSelect,
	listaDesconforto: state.desconforto.listaSelect,
	listaAvaliacaoPatologia: state.avaliacao.listaAvaliacaoPatologia,
	listaAvaliacaoExercicio: state.avaliacao.listaAvaliacaoExercicio,
	listaAvaliacaoAtividade: state.avaliacao.listaAvaliacaoAtividade,
	listaAvaliacaoExercicioRecomendacao: state.avaliacao.listaAvaliacaoExercicioRecomendacao,
	listaAvaliacaoRecomendacao: state.avaliacao.listaAvaliacaoRecomendacao,
	listaAvaliacaoDesconforto: state.avaliacao.listaAvaliacaoDesconforto,
	listaMeta: state.avaliacao.listaMeta,
	listaSimNao: state.avaliacao.listaSimNao,
	listaNivel: state.avaliacao.listaNivel,
	listaSimNaoQuantidade: state.avaliacao.listaSimNaoQuantidade,
	listaImc: state.avaliacao.listaImc,
	listaGorduraCorporal: state.avaliacao.listaGorduraCorporal,
	listaMusculoEsqueletico: state.avaliacao.listaMusculoEsqueletico,
	listaGorduraVisceral: state.avaliacao.listaGorduraVisceral,
	listaIdadeCorporal: state.avaliacao.listaIdadeCorporal
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm, salvar,
	getListaAvaliacaoPatologia, salvarPatologia,
	getListaAvaliacaoExercicio, salvarExercicio,
	getListaAvaliacaoAtividade, salvarAtividade,
	getListaAvaliacaoExercicioRecomendacao, salvarExercicioRecomendacao,
	getListaAvaliacaoRecomendacao, salvarRecomendacao,
	getListaAvaliacaoDesconforto, salvarDesconforto,
	getListaMeta, salvarMeta
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(AvaliacaoForm);
