import React from 'react';
import { Switch, Route, Redirect } from 'react-router';

import AuthOrApp from './authOrApp'
import Dashboard from '../dashboard/dashboard';
import UsuarioPerfil from '../usuarioPerfil/usuarioPerfil';
import Usuario from '../usuario/usuario';
import Cliente from '../cliente/cliente';
import Unidade from '../unidade/unidade';
import Setor from '../setor/setor';
import Cargo from '../cargo/cargo';
import UsuarioColaborador from '../usuario/usuarioColaborador';
import Patologia from '../patologia/patologia';
import Exercicio from '../exercicio/exercicio';
import Atividade from '../atividade/atividade';
import Recomendacao from '../recomendacao/recomendacao';
import Desconforto from '../desconforto/desconforto';
import Chamada from '../chamada/chamada';
import ChamadaMensal from '../chamada/chamadaMensal';
import ChamadaParticipacao from '../chamada/chamadaParticipacao';
import Avaliacao from '../avaliacao/avaliacao';
import ResultadoIndividual from '../resultadoIndividual/resultadoIndividual';
import ResultadoColetivo from '../resultadoColetivo/resultadoColetivo';

export default props => (
	<div id='id-content-wrapper' className='content-wrapper' >
		<Switch>
			<Route exact path='/' component={Dashboard} />
			<Route path='/usuarioPerfil' component={UsuarioPerfil} />
			<Route path='/usuario' component={Usuario} />
			<Route path='/cliente' component={Cliente} />
			<Route path='/unidade' component={Unidade} />
			<Route path='/setor' component={Setor} />
			<Route path='/cargo' component={Cargo} />
			<Route path='/usuarioColaborador' component={UsuarioColaborador} />
			<Route path='/patologia' component={Patologia} />
			<Route path='/exercicio' component={Exercicio} />
			<Route path='/atividade' component={Atividade} />
			<Route path='/recomendacao' component={Recomendacao} />
			<Route path='/desconforto' component={Desconforto} />
			<Route path='/chamada' component={Chamada} />
			<Route path='/chamadaParticipacao' component={ChamadaParticipacao} />
			<Route path='/chamadaMensal' component={ChamadaMensal} />
			<Route path='/avaliacao' component={Avaliacao} />
			<Route path='/resultadoIndividual' component={ResultadoIndividual} />
			<Route path='/resultadoColetivo' component={ResultadoColetivo} />
			<Redirect from='*' to='/' />
		</Switch>
	</div>
);
