const INITIAL_STATE = {
	registro: {},
	modoTela: 'lista',
	aguardando: false,
	lista: [],
	listaAvaliacaoPatologia: [],
	listaAvaliacaoExercicio: [],
	listaAvaliacaoAtividade: [],
	listaAvaliacaoExercicioRecomendacao: [],
	listaAvaliacaoRecomendacao: [],
	listaAvaliacaoDesconforto: [],
	listaMeta: [],
	listaStatus: [],
	listaSimNao: [{ id: 0, valor: 'Não'}, { id: 1, valor: 'Sim'}],
	listaNivel: [{ id: 1, valor: 'Confortável' }, { id: 2, valor: 'Leve Desconforto' }, { id: 3, valor: 'Desconforto' }],
	listaSimNaoQuantidade: [
		{ id: 0, valor: 'Não'}, { id: 1, valor: 'Sim, 1 vez'}, { id: 2, valor: 'Sim, 2 vezes'}, { id: 3, valor: 'Sim, 3 vezes'}, 
		{ id: 4, valor: 'Sim, 4 vezes'}, { id: 5, valor: 'Sim, 5 vezes'}, { id: 6, valor: 'Sim, 6 vezes'}, { id: 7, valor: 'Sim, 7 vezes'}
	],
	listaImc: [
		{ inicio: 0, fim: 18.9, descricao: 'Abaixo do peso ideal (18,9)' },
		{ inicio: 19, fim: 24.9, descricao: 'Peso ideal (de 19 até 24,9)' },
		{ inicio: 25, fim: 29.9, descricao: 'Acima do peso ideal (de 25 até 29,9)' },
		{ inicio: 30, fim: 34.9, descricao: 'Obesidade Grau I  (de 30 até 34,9)' },
		{ inicio: 35, fim: 39.9, descricao: 'Obesidade Grau II (de 35 até 39,9)' },
		{ inicio: 40, fim: 1000, descricao: 'Obesidade Grau III (Acima de 40)' }
	],
	listaGorduraCorporal: [
		{ sexo: 1, idadeInicio: 18, idadeFim: 39, inicio: 0, fim: 20.9, descricao: 'Baixo' },
		{ sexo: 1, idadeInicio: 40, idadeFim: 59, inicio: 0, fim: 22.9, descricao: 'Baixo' },
		{ sexo: 1, idadeInicio: 60, idadeFim: 79, inicio: 0, fim: 23.9, descricao: 'Baixo' },
		{ sexo: 1, idadeInicio: 18, idadeFim: 39, inicio: 21, fim: 32.9, descricao: 'Normal' },
		{ sexo: 1, idadeInicio: 40, idadeFim: 59, inicio: 23, fim: 33.9, descricao: 'Normal' },
		{ sexo: 1, idadeInicio: 60, idadeFim: 79, inicio: 24, fim: 35.9, descricao: 'Normal' },
		{ sexo: 1, idadeInicio: 18, idadeFim: 39, inicio: 33, fim: 38.9, descricao: 'Alto' },
		{ sexo: 1, idadeInicio: 40, idadeFim: 59, inicio: 34, fim: 39.9, descricao: 'Alto' },
		{ sexo: 1, idadeInicio: 60, idadeFim: 79, inicio: 36, fim: 41.9, descricao: 'Alto' },
		{ sexo: 1, idadeInicio: 18, idadeFim: 39, inicio: 39, fim: 100, descricao: 'Muito Alto' },
		{ sexo: 1, idadeInicio: 40, idadeFim: 59, inicio: 40, fim: 100, descricao: 'Muito Alto' },
		{ sexo: 1, idadeInicio: 60, idadeFim: 79, inicio: 42, fim: 100, descricao: 'Muito Alto' },
		
		{ sexo: 2, idadeInicio: 18, idadeFim: 39, inicio: 0, fim: 7.9, descricao: 'Baixo' },
		{ sexo: 2, idadeInicio: 40, idadeFim: 59, inicio: 0, fim: 10.9, descricao: 'Baixo' },
		{ sexo: 2, idadeInicio: 60, idadeFim: 79, inicio: 0, fim: 12.9, descricao: 'Baixo' },
		{ sexo: 2, idadeInicio: 18, idadeFim: 39, inicio: 8, fim: 19.9, descricao: 'Normal' },
		{ sexo: 2, idadeInicio: 40, idadeFim: 59, inicio: 11, fim: 21.9, descricao: 'Normal' },
		{ sexo: 2, idadeInicio: 60, idadeFim: 79, inicio: 13, fim: 24.9, descricao: 'Normal' },
		{ sexo: 2, idadeInicio: 18, idadeFim: 39, inicio: 20, fim: 24.9, descricao: 'Alto' },
		{ sexo: 2, idadeInicio: 40, idadeFim: 59, inicio: 22, fim: 27.9, descricao: 'Alto' },
		{ sexo: 2, idadeInicio: 60, idadeFim: 79, inicio: 25, fim: 29.9, descricao: 'Alto' },
		{ sexo: 2, idadeInicio: 18, idadeFim: 39, inicio: 25, fim: 100, descricao: 'Muito Alto' },
		{ sexo: 2, idadeInicio: 40, idadeFim: 59, inicio: 28, fim: 100, descricao: 'Muito Alto' },
		{ sexo: 2, idadeInicio: 60, idadeFim: 79, inicio: 30, fim: 100, descricao: 'Muito Alto' },
	],
	listaMusculoEsqueletico: [
		{ sexo: 1, idadeInicio: 18, idadeFim: 39, inicio: 0, fim: 24.2, descricao: 'Baixo' },
		{ sexo: 1, idadeInicio: 40, idadeFim: 59, inicio: 0, fim: 24.0 , descricao: 'Baixo' },
		{ sexo: 1, idadeInicio: 60, idadeFim: 79, inicio: 0, fim: 23.8, descricao: 'Baixo' },
		{ sexo: 1, idadeInicio: 18, idadeFim: 39, inicio: 24.3, fim: 30.3, descricao: 'Normal' },
		{ sexo: 1, idadeInicio: 40, idadeFim: 59, inicio: 24.1, fim: 30.1, descricao: 'Normal' },
		{ sexo: 1, idadeInicio: 60, idadeFim: 79, inicio: 23.9, fim: 29.9, descricao: 'Normal' },
		{ sexo: 1, idadeInicio: 18, idadeFim: 39, inicio: 30.4, fim: 35.3, descricao: 'Alto' },
		{ sexo: 1, idadeInicio: 40, idadeFim: 59, inicio: 30.2, fim: 35.1, descricao: 'Alto' },
		{ sexo: 1, idadeInicio: 60, idadeFim: 79, inicio: 30, fim: 34.9, descricao: 'Alto' },
		{ sexo: 1, idadeInicio: 18, idadeFim: 39, inicio: 35.4, fim: 100, descricao: 'Muito Alto' },
		{ sexo: 1, idadeInicio: 40, idadeFim: 59, inicio: 35.2, fim: 100, descricao: 'Muito Alto' },
		{ sexo: 1, idadeInicio: 60, idadeFim: 79, inicio: 35.0, fim: 100, descricao: 'Muito Alto' },
		{ sexo: 2, idadeInicio: 18, idadeFim: 39, inicio: 0, fim: 33.2, descricao: 'Baixo' },
		{ sexo: 2, idadeInicio: 40, idadeFim: 59, inicio: 0, fim: 33.0, descricao: 'Baixo' },
		{ sexo: 2, idadeInicio: 60, idadeFim: 79, inicio: 0, fim: 32.8, descricao: 'Baixo' },
		{ sexo: 2, idadeInicio: 18, idadeFim: 39, inicio: 33.3, fim: 39.3, descricao: 'Normal' },
		{ sexo: 2, idadeInicio: 40, idadeFim: 59, inicio: 33.1, fim: 39.1, descricao: 'Normal' },
		{ sexo: 2, idadeInicio: 60, idadeFim: 79, inicio: 32.9, fim: 38.9, descricao: 'Normal' },
		{ sexo: 2, idadeInicio: 18, idadeFim: 39, inicio: 39.4, fim: 44.0, descricao: 'Alto' },
		{ sexo: 2, idadeInicio: 40, idadeFim: 59, inicio: 39.2, fim: 43.8, descricao: 'Alto' },
		{ sexo: 2, idadeInicio: 60, idadeFim: 79, inicio: 39.0, fim: 43.6, descricao: 'Alto' },
		{ sexo: 2, idadeInicio: 18, idadeFim: 39, inicio: 44.1, fim: 100, descricao: 'Muito Alto' },
		{ sexo: 2, idadeInicio: 40, idadeFim: 59, inicio: 43.9, fim: 100, descricao: 'Muito Alto' },
		{ sexo: 2, idadeInicio: 60, idadeFim: 79, inicio: 43.7, fim: 100, descricao: 'Muito Alto' }
	],
	listaGorduraVisceral: [
		{ inicio: 0, fim: 9.9, descricao: 'Normal' },
		{ inicio: 10, fim: 14.9, descricao: 'Alto' },
		{ inicio: 15, fim: 100, descricao: 'Muito Alto' }
	],
	listaIdadeCorporal: [
		{ inicio: 0, fim: 19, descricao: 'Menos de 20 anos' },
		{ inicio: 20, fim: 39, descricao: 'Entre 20 e 39 anos' },
		{ inicio: 40, fim: 59, descricao: 'Entre 40 e 59 anos' },
		{ inicio: 60, fim: 79, descricao: 'Entre 60 e 79 anos' }
	],
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'AVALIACAO_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

		case 'AVALIACAO_SET_AGUARDANDO':
			return { ...state,
				aguardando: action.payload
			};

        case 'AVALIACAO_LISTADO':
            return {
				...state,
				lista: action.payload.data
			};

		case 'AVALIACAO_STATUS_SELECT_LISTADO':
            return {
				...state,
				listaStatus: action.payload.data
			};

		case 'AVALIACAO_PATOLOGIA_LISTADO':
            return {
				...state,
				listaAvaliacaoPatologia: action.payload.data
			};

		case 'AVALIACAO_EXERCICIO_LISTADO':
            return {
				...state,
				listaAvaliacaoExercicio: action.payload.data
			};

		case 'AVALIACAO_ATIVIDADE_LISTADO':
            return {
				...state,
				listaAvaliacaoAtividade: action.payload.data
			};

		case 'AVALIACAO_EXERCICIO_RECOMENDACAO_LISTADO':
            return {
				...state,
				listaAvaliacaoExercicioRecomendacao: action.payload.data
			};

		case 'AVALIACAO_RECOMENDACAO_LISTADO':
            return {
				...state,
				listaAvaliacaoRecomendacao: action.payload.data
			};

		case 'AVALIACAO_DESCONFORTO_LISTADO':
            return {
				...state,
				listaAvaliacaoDesconforto: action.payload.data
			};

		case 'AVALIACAO_META_LISTADO':
            return {
				...state,
				listaMeta: action.payload.data
			};

        default:
            return state;
    }
}
